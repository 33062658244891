import React from "react"
import { Container } from "react-bootstrap"
import styles from "../../scss/components/Esports/_Main.module.scss"

export default function Main() {
  return (
    <div className={styles.container}>
      <div className={styles.textsContainer}>
        <h1 className={styles.firstText}>Nunca viste una misión tan fácil</h1>
        <h2 className={styles.secondText}>
        Completá el formulario y ganá un par de entradas para GAMERGY en Tecnópolis del 15 al 17 de Abril.
        </h2>
      </div>
    </div>
  )
}
